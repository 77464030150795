import { Container } from '@material-ui/core';
import { QuestionTableCard } from '@drodil/backstage-plugin-qeta';
import React from 'react';

export const EntityQetaContent = () => {

  return (
    <Container>
      <QuestionTableCard />
    </Container>
  );
};